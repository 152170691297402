import * as React from "react";
import { graphql } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";
import { Container, Typography, Box, Stack } from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import { Layout } from "../components/layout";

const IndexPage = () => {
  const searchInput = React.useRef<HTMLInputElement>(null);

  return (
    <Layout ref={searchInput}>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            <Trans>Clinical Trials</Trans>
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Helping others by contributing to medical research
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button variant="contained" to="/about">
              <Trans>Learn more</Trans>
            </Button>
            <Button
              variant="outlined"
              onClick={(el) => searchInput.current?.focus()}
            >
              <Trans>Search</Trans>
            </Button>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default IndexPage;
export { Head } from "../head";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
